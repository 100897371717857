<template>
  <!-- 自定义分群 -->
  <div class="content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="搜索">
              <el-input
                clearable
                placeholder="输入名称搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="人群名称" prop="Name"></el-table-column>
        <el-table-column label="人群定义" prop="define"></el-table-column>
        <el-table-column label="创建人" prop="create"></el-table-column>
        <el-table-column label="创建时间" prop="Time"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >人群(500人)</el-button
            >
            <el-button type="danger" @click="deleter(scope.row)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="人群名称" prop="Name">
                <el-input
                  clearable
                  v-model="ruleForm.Name"
                  placeholder="请输入人群名称"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="人群定义" prop="define">
                <el-input
                  clearable
                  v-model="ruleForm.define"
                  placeholder="请输入人群定义"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="创建人" prop="create">
                <el-input
                  clearable
                  v-model="ruleForm.create"
                  placeholder="请填写创建人"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间" prop="Time">
                <el-date-picker
                  v-model="ruleForm.Time"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
                <el-radio v-model="ruleForm.Active" :label="true"
                  >有效</el-radio
                >
                <el-radio v-model="ruleForm.Active" :label="false"
                  >无效</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CustomerClustering",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: "", //删除权限
      dialogtype: 1,
      dialogVisible: false,
      searchName: "",
      tableData: [
        {
          Name: "散客",
          define: "人群定义",
          create: "创建人",
          Time: "2021-07-08",
          Active: true,
        },
      ],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        Active: true,
      },
      rules: {
        Name: [
          { required: true, message: "请输入人群名称", trigger: "blur" },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    handleSearch() {
      var that = this;
      var params = {
        // Active:that.Active,
        Name: that.searchName,
        PageNum: that.paginations.page,
      };
      return;
      API.skinCondition(params).then((res) => {
        if (res.StateCode == 200) {
          // that.tableData = res.List;
          // that.paginations.total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    addParts() {
      this.dialogtype = 1;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editor(row) {
      const { Active, Name, Time, create, define } = row;
      this.ruleForm = { Active, Name, Time, create, define };
      this.dialogtype = 2;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    closeDialog() {
      this.ruleForm = {
        Active: true,
      };
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        }
      });
    },
    createParts() {
      var that = this;
      return;
      API.create(this.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    updateParts() {
      var that = this;
      return;
      API.update(this.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          this.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
